import React, { useState } from "react";
import { Formik } from "formik";
import { toast } from "react-toastify";
import requests from "../../../services/requests";
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";
import moment from "moment";
import Select from "react-select";
import { FaSave, FaPencilAlt } from "react-icons/fa";
import ModalDriverFreightTrajectory from "./ModalDriverFreightTrajectory";
import api from "../../../services/api";
import * as yup from "yup";

registerLocale("pt", pt);

const DriverFreightDetails = ({ driverFreight, vehicles, drivers, getEntity }) => {
    const [fieldEditing, setFieldEditing] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [trajectories, setTrajectories] = useState(driverFreight.trajectories);

    var statusToCancel = ["DRAFT", "CREATED", "SENT_TO_DRIVER", "REFUSED_BY_DRIVER", "ACCEPTED_BY_DRIVER"];

    var isPossibleEdit = false;
    if (driverFreight.status === "DRAFT") {
        isPossibleEdit = true;
    }

    var isPossibleEditVehicle = false;
    if (driverFreight.status === "DRAFT" || driverFreight.status === "REFUSED_BY_DRIVER") {
        isPossibleEditVehicle = true;
    }

    // const actionModal = () => {
    //     setOpenModal(!openModal);
    // };

    const closeModal = () => {
        setOpenModal(false);
    };

    function EditContent(props) {
        if (driverFreight.status === "DRAFT") {
            return fieldEditing === props.type ? (
                <>
                    <a
                        type="button"
                        className="cursor-pointer"
                        onClick={e => {
                            e.preventDefault();
                            setFieldEditing("");
                            onSubmit(props.values);
                        }}>
                        <FaSave size={23} color="#422C76" />
                    </a>
                </>
            ) : (
                isPossibleEdit && (
                    <a
                        type="button"
                        className="cursor-pointer"
                        onClick={e => {
                            setFieldEditing(props.type);
                        }}>
                        <FaPencilAlt size={23} color="#422C76" />
                    </a>
                )
            );
        } else if (driverFreight.status === "REFUSED_BY_DRIVER" && props.type === "driver") {
            return fieldEditing === "driver" ? (
                <>
                    <a
                        type="button"
                        className="cursor-pointer"
                        onClick={() => {
                            if (props.values.driver === null) {
                                toast.error("Selecione um motorista!");
                            } else {
                                setFieldEditing("");
                                onSubmit(props.values);
                            }
                        }}>
                        <FaSave size={23} color="#422C76" />
                    </a>
                </>
            ) : (
                isPossibleEditVehicle && (
                    <a
                        type="button"
                        className="cursor-pointer"
                        onClick={() => {
                            setFieldEditing(props.type);
                        }}>
                        <FaPencilAlt size={23} color="#422C76" />
                    </a>
                )
            );
        }

        return null;
    }

    const cancelDriverFreight = identifier => {
        return api
            .post(
                `/adm/driver-freight/${identifier}/change-status/cancel`,
                {},
                {
                    headers: {
                        Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                    },
                }
            )
            .then(response => {
                toast.success("Viagem cancelada com sucesso!");
                window.location.href = "/gestao-de-viagens/kanban";
                getEntity();
            });
    };

    const onSubmit = values => {
        let data = {
            vehicle: values.vehicle.value,
            driver: values.driver ? values.driver.value : null,
            startsAt: values.startsAt ? moment(values.startsAt).format("DD/MM/yyyy HH:mm:ss") : null,
            endsAt: values.endsAt ? moment(values.endsAt).format("DD/MM/yyyy HH:mm:ss") : null,
            startingFrom: values.startingFrom,
            destinationIn: values.destinationIn,
            trajectories: values.trajectories?.length > 0 ? values.trajectories : trajectories,
        };

        requests
            .editDriverFreight(driverFreight.identifier, data)
            .then(response => {
                getEntity();
                toast.success("Viagem editada com sucesso!");
            })
            .catch(error => {
                console.log(error);
            });
    };

    return (
        <>
            <ModalDriverFreightTrajectory
                actionModal={closeModal}
                openModal={openModal}
                driverFreight={driverFreight}
                getEntity={getEntity}
                trajectories={driverFreight.trajectories}
                handleSuccess={() => {
                    closeModal();
                    getEntity();
                }}
            />
            <Formik
                enableReinitialize={true}
                initialValues={{
                    driver: { value: driverFreight?.userDriverIdentifier, label: driverFreight?.userDriverName },
                    vehicle: { value: driverFreight?.vehicle?.identifier, label: driverFreight?.vehicle?.licensePlate },
                    startingFrom: driverFreight?.startingFrom,
                    destinationIn: driverFreight?.destinationIn,
                    startsAt: driverFreight?.startsAt,
                    endsAt: driverFreight?.endsAt,
                    trajectories: trajectories,
                }}
                validationSchema={yup.object().shape({
                    vehicle: yup.string().nullable(),
                    parent: yup.string().nullable(),
                    destinationIn: yup.string().required("Campo obrigatório."),
                    startingFrom: yup.string().required("Campo obrigatório."),
                    startsAt: yup.string().required("Campo obrigatório.").nullable(),
                    endsAt: yup.string().nullable(),
                    trajectories: yup
                        .array()
                        .of(
                            yup.object().shape({
                                cteNumber: yup.string().required("Campo obrigatório."),
                                address: yup.string().required("Campo obrigatório."),
                            })
                        )
                        .required("Deve ter trajetos.")
                        .min(1, "Preencha no minímo um trajeto."),
                })}
                // onSubmit={(values, { setFieldError, setSubmitting }) => {
                //     setSubmitting(true);
                //     onSubmit(values, setSubmitting);
                // }}
            >
                {({ setFieldValue, handleSubmit, values }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="w-full">
                            <div className="flex h-16 items-center">
                                <div className="w-10/12">
                                    <label htmlFor="vehicle" className="text-roxo_oficial font-bold">
                                        Motorista
                                    </label>
                                    {fieldEditing === "driver" ? (
                                        <>
                                            <Select
                                                isClearable
                                                name="driver"
                                                value={values.driver}
                                                className="block uppercase text-blueGray-600 text-xs font-bold"
                                                placeholder="Selecione um motorista"
                                                onChange={option => {
                                                    setFieldValue("driver", option);
                                                }}
                                                options={drivers}
                                            />
                                        </>
                                    ) : (
                                        <p className="text-C6 text-sm">
                                            {driverFreight.userDriverName ? driverFreight.userDriverName : "Sem motorista"}
                                        </p>
                                    )}
                                </div>
                                <div className="w-2/12 pl-6 items-center">
                                    <EditContent type="driver" values={values} />
                                </div>
                            </div>
                        </div>

                        <div className="w-full">
                            <div className="flex h-16 items-center stripped">
                                <div className="w-10/12">
                                    <label htmlFor="vehicle" className="text-roxo_oficial font-bold">
                                        Veículo
                                    </label>
                                    {fieldEditing === "vehicle" && driverFreight.status === "DRAFT" ? (
                                        <>
                                            <Select
                                                isClearable
                                                name="vehicle"
                                                value={values.vehicle}
                                                className="block uppercase text-blueGray-600 text-xs font-bold"
                                                placeholder="Selecione um veículo"
                                                onChange={option => {
                                                    setFieldValue("vehicle", option);
                                                }}
                                                options={vehicles}
                                            />
                                        </>
                                    ) : (
                                        <p className="text-C6 text-sm">
                                            {driverFreight.vehicle?.licensePlate
                                                ? driverFreight.vehicle?.licensePlate
                                                : "Sem veículo"}
                                        </p>
                                    )}
                                </div>
                                <div className="w-2/12 pl-6 items-center">
                                    <EditContent type="vehicle" values={values} />
                                </div>
                            </div>
                        </div>

                        <div className="w-full">
                            <div className="flex h-16 items-center">
                                <div className="w-10/12">
                                    <label htmlFor="startsAt" className="text-roxo_oficial font-bold">
                                        Data e horário inicial
                                    </label>
                                    {fieldEditing === "startsAt" ? (
                                        <>
                                            <DatePicker
                                                locale="pt"
                                                selected={values.startsAt && new Date(values.startsAt)}
                                                dateFormat="Pp"
                                                className="rounded-md border border-C2 h-9 py-1 px-5"
                                                name="startsAt"
                                                onChange={date => {
                                                    setFieldValue("startsAt", date);
                                                    setFieldValue("endsAts", null);
                                                }}
                                                placeholderText="Selecione uma data e horário inicial"
                                                showTimeSelect
                                                isClearable
                                            />
                                        </>
                                    ) : (
                                        <p className="text-C6 text-sm">
                                            {driverFreight.startsAt
                                                ? moment(driverFreight.startsAt).format("DD/MM/YYYY - HH:mm")
                                                : "Sem data"}
                                        </p>
                                    )}
                                </div>
                                <div className="w-2/12 pl-6 items-center">
                                    <EditContent type="startsAt" values={values} />
                                </div>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex h-16 items-center stripped">
                                <div className="w-10/12">
                                    <label htmlFor="endsAt" className="text-roxo_oficial font-bold">
                                        Data e horário final
                                    </label>
                                    {fieldEditing === "endsAt" ? (
                                        <>
                                            <DatePicker
                                                locale="pt"
                                                selected={values.endsAt && new Date(values.endsAt)}
                                                dateFormat="Pp"
                                                className="rounded-md border border-C2 h-9 py-1 px-5"
                                                name="endsAt"
                                                onChange={date => {
                                                    setFieldValue("endsAt", date);
                                                }}
                                                placeholderText="Selecione uma data e horário final"
                                                showTimeSelect
                                                isClearable
                                            />
                                        </>
                                    ) : (
                                        <p className="text-C6 text-sm">
                                            {driverFreight.endsAt
                                                ? moment(driverFreight.endsAt).format("DD/MM/YYYY - HH:mm")
                                                : "Sem data"}
                                        </p>
                                    )}
                                </div>
                                <div className="w-2/12 pl-6 items-center">
                                    <EditContent type="endsAt" values={values} />
                                </div>
                            </div>
                        </div>

                        <div className={`w-full ${isPossibleEdit ? "mt-6" : "mt-2"}`}>
                            <div className={`${isPossibleEdit ? "flex h-full items-center" : ""}`}>
                                <div className={`${isPossibleEdit ? "w-10/12" : "w-full"}`}>
                                    <label htmlFor="trajectory" className="text-roxo_oficial font-bold">
                                        Trechos
                                    </label>

                                    <table className="table-auto w-full text-left" id="table-responsive">
                                        <thead className="border-b border-roxo_oficial">
                                            <td>Tipo</td>
                                            <td>Endereço</td>
                                        </thead>
                                        <tbody>
                                            {driverFreight?.trajectories?.length > 0 &&
                                                driverFreight?.trajectories?.map((trajectory, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td className="pr-3">{trajectory.type}</td>
                                                            <td className="pr-3">{trajectory.address}</td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="w-2/12 pl-6 items-center">
                                    {isPossibleEdit && (
                                        <a
                                            type="button"
                                            className="cursor-pointer"
                                            onClick={e => {
                                                setOpenModal("trajectory");
                                            }}>
                                            <FaPencilAlt size={23} color="#422C76" />
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                        {statusToCancel.includes(driverFreight.status) && (
                            <div className="inline-block w-full mt-10">
                                <button
                                    onClick={() => cancelDriverFreight(driverFreight.identifier)}
                                    className="inline-block float-right bg-canal_vermelho rounded-md text-white outline-none p-2">
                                    <span>Cancelar viagem</span>
                                </button>
                            </div>
                        )}
                    </form>
                )}
            </Formik>
        </>
    );
};

export default DriverFreightDetails;
